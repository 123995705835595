class OProduct {
	constructor(element) {
		this.formElement = element.querySelector('.m-form-add-to-cart');
		this.inputQuantityElements = element.querySelector('input[name="quantity"]');
		this.inputRadioUuidElements = element.querySelectorAll('input[type="radio"][name="uuid"]');
		this.cart = window.cart;

		this.formElement?.addEventListener('submit', async (/** @var {SubmitEvent} */ event) => {
			event.preventDefault();

			const submitElement = event.submitter;
			Object.assign(submitElement.dataset, {
				loading: '',
			});

			const uuid = event.target.elements.namedItem('uuid').value;
			await window.cart.add(uuid, this.inputQuantityElements.value);

			window.cart.element.setAttribute('open', '');
			document.scrollingElement.scroll({
				top: 0,
				behvior: 'smooth',
			});
			Object.assign(submitElement.dataset, {
				loading: null,
			});
			return false;
		});
		this.inputRadioUuidElements.forEach((_) => _.addEventListener('change', (event) => {
			const variantTogglesInputElement = event.target;
			if (variantTogglesInputElement.dataset.stock) {
				this.inputQuantityElements.max = variantTogglesInputElement.dataset.stock;
			} else {
				this.inputQuantityElements.max = null;
			}
		}));
	}
}

document.querySelectorAll('.o-product').forEach((productElement) => new OProduct(productElement));
