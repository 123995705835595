/* global Stripe */

/** @typedef {import("@stripe/stripe-js/dist/stripe-js/index").Stripe} Stripe */
/** @typedef {import("@stripe/stripe-js/dist/stripe-js/index").StripeElements} StripeElements */

class OCheckout {
	sumFloat = null;

	constructor(element) {
		this.element = element;
		this.formElement = element.querySelector('#checkout-form');
		this.discountCodeElement = element.querySelector('input[name="discount_code"]');
		this.addDiscountButtonElement = element.querySelector('button[name="add-discount"]');
		this.removeDiscountButtonElement = element.querySelector('button[name="remove-discount"]');
		this.checkoutDiscountFormElement = document.getElementById('checkout-discount-form');
		this.mFormCheckoutErrorElement = element.querySelector('.m-form-checkout__error');
		this.paymentMethodsElement = element.querySelector('.m-payment-methods');
		this.submitElement = element.querySelector('button[type="submit"]');
		this.paymentMethodInputElements = element.querySelectorAll('input[name="paymentMethod"]');

		// ELEMENTS
		const { submitElement } = this;
		const paymentMethodFieldElements = element.querySelectorAll('.m-field[data-payment-method]');

		// METHODS
		// Helper method to create a div
		this.createErrorElement = (message) => {
			const errorElement = document.createElement('div');
			errorElement.classList.add('a-text');
			errorElement.dataset.type = 'error';
			errorElement.textContent = message;
			return errorElement;
		};
		this.createFieldErrors = (details) => {
			console.log(details);
			Object.keys(details).forEach((key) => {
				const fieldElement = element.querySelector(`.m-field[data-name="${key}"]`);
				Object.values(details[key].message).forEach((message) => {
					if (fieldElement) {
						fieldElement.appendChild(this.createErrorElement(message));
					} else {
						this.mFormCheckoutErrorElement.appendChild(this.createErrorElement(message));
					}
				});
			});
		};

		// FUNCTIONS
		function updatePaymentMethods(paymentMethodInputElement) {
			const paymentMethod = paymentMethodInputElement.value;

			if (paymentMethodInputElement.dataset.submitText) {
				submitElement.innerText = paymentMethodInputElement.dataset.submitText;
			} else {
				submitElement.innerText = submitElement.dataset.defaultSubmitText;
			}

			paymentMethodFieldElements.forEach((paymentMethodFieldElement) => {
				if (paymentMethod === paymentMethodFieldElement.dataset.paymentMethod) {
					paymentMethodFieldElement.removeAttribute('hidden');
				} else {
					paymentMethodFieldElement.setAttribute('hidden', '');
				}
			});
		}

		// INITS
		this.#updateDiscount();

		window.cart.element.addEventListener('update', (event) => {
			this.sumFloat = event.detail.data.sumFloat;
			this.onCartUpdate(this.sumFloat);
		});

		// Init Stripe
		const stripePublishableKey = element.querySelector('input[name="stripePublishableKey"]')?.value ?? null;
		const stripeStyle = {
			base: {
				fontFamily: getComputedStyle(document.documentElement).getPropertyValue('--font-family'),
				fontSize: '17px',
			},
		};

		// check if stripePublishableKey is null or empty
		if (stripePublishableKey) {
			/** @type {Stripe} */
			this.stripe = Stripe(stripePublishableKey);
			/** @type {StripeElements} */
			this.stripeElements = this.stripe.elements({
				locale: document.querySelector('html').lang,
			});

			if (document.getElementById('stripe-card')) {
				// Create Stripe Card Element
				// https://stripe.com/docs/js/elements_object/create_element
				const stripeCardElement = this.stripeElements.create('card', {
					hidePostalCode: true,
					style: stripeStyle,
					disableLink: true,
				});
				// https://stripe.com/docs/js/element/mount
				stripeCardElement.mount('#stripe-card');
			}

			if (document.getElementById('stripe-iban')) {
				// Create Stripe IBAN Element
				// https://stripe.com/docs/js/elements_object/create_element
				const stripeIbanElement = this.stripeElements.create('iban', {
					supportedCountries: ['SEPA'],
					style: stripeStyle,
				});
				// https://stripe.com/docs/js/element/mount
				stripeIbanElement.mount('#stripe-iban');
			}
		}

		this.submitElement.dataset.defaultSubmitText = this.submitElement.innerText;

		// EVENT LISTENER
		this.paymentMethodInputElements.forEach((paymentMethodInputElement) => {
			if (paymentMethodInputElement.checked) {
				updatePaymentMethods(paymentMethodInputElement);
			}

			paymentMethodInputElement.addEventListener('change', (event) => {
				updatePaymentMethods(event.target);
			});
		});

		this.formElement.addEventListener('submit', this.onSubmit.bind(this));
		this.checkoutDiscountFormElement.addEventListener('submit', this.onDiscountSubmit.bind(this));
	}

	// eslint-disable-next-line class-methods-use-this
	getClientSecret(paymentMethod) {
		const { protocol, host } = window.location;
		const url = new URL('/api/shop/client-secret', `${protocol}//${host}`);
		url.searchParams.set('payment-method', paymentMethod);
		return fetch(url)
			.then((response) => response.json())
			.then((data) => {
				if (data.clientSecret) {
					return data.clientSecret;
				}
				throw data?.message;
			});
	}

	set loading(isLoading = true) {
		this.submitElement.toggleAttribute('data-loader', isLoading);
		this.element.querySelectorAll('button, fieldset, input, select, textarea').forEach((inputElement) => {
			if (isLoading === true) {
				inputElement.setAttribute('disabled', '');
			} else {
				inputElement.removeAttribute('disabled');
			}
		});
	}

	get loading() {
		return this.submitElement.hasAttribute('data-loader');
	}

	onCartUpdate(sum) {
		const { submitElement } = this;
		if (sum === 0.0) {
			submitElement.innerText = 'Weihnachtsgeschenk bestellen';
			this.paymentMethodsElement.classList.add('a-visually-hidden');
			this.paymentMethodInputElements.forEach((_) => _.removeAttribute('required'));
		} else {
			submitElement.innerText = submitElement.dataset.defaultSubmitText;
			this.paymentMethodsElement.classList.remove('a-visually-hidden');
			this.paymentMethodInputElements.forEach((_) => _.toggleAttribute('required', true));
		}
	}

	#updateDiscount() {
		const { discountCodeElement } = this;
		const discountCodeElementIsEmpty = !discountCodeElement.value.trim() || discountCodeElement.hasAttribute('aria-invalid');
		this.addDiscountButtonElement.toggleAttribute('hidden', !discountCodeElementIsEmpty);
		this.discountCodeElement.toggleAttribute('readonly', !discountCodeElementIsEmpty);
		this.removeDiscountButtonElement.toggleAttribute('hidden', discountCodeElementIsEmpty);
	}

	async #setDiscount() {
		const discountCodeElement = this.formElement.querySelector('[name="discount_code"]');
		const { value } = discountCodeElement;

		// Remove error elements
		this.formElement.querySelectorAll('.a-text[data-type="error"]').forEach((errorElement) => errorElement.remove());

		this.loading = true;
		this.addDiscountButtonElement.toggleAttribute('data-loader', true);
		discountCodeElement.removeAttribute('aria-invalid');
		if (value.trim()) {
			const response = await window.cart.setDiscount(value);
			if (response.status === 'error') {
				discountCodeElement.toggleAttribute('aria-invalid', true);
				this.mFormCheckoutErrorElement.appendChild(this.createErrorElement(response.message));
			}
		}

		this.addDiscountButtonElement.toggleAttribute('data-loader', false);
		this.loading = false;

		this.#updateDiscount();
	}

	async #removeDiscount() {
		this.loading = true;
		this.removeDiscountButtonElement.toggleAttribute('data-loader', true);

		this.discountCodeElement.value = '';
		await window.cart.removeDiscount();

		this.removeDiscountButtonElement.toggleAttribute('data-loader', false);
		this.loading = false;

		this.#updateDiscount();
	}

	onDiscountSubmit(event) {
		event.preventDefault();
		if (event.submitter === this.addDiscountButtonElement) {
			this.#setDiscount();
		} else if (event.submitter === this.removeDiscountButtonElement) {
			this.#removeDiscount();
		}

		return false;
	}

	async onSubmit(event) {
		const { stripe, formElement } = this;
		let paymentMethod = formElement.elements.namedItem('paymentMethod')?.value ?? null;
		const formData = new FormData(formElement);

		event.preventDefault();
		this.loading = true;

		// Remove error elements
		formElement.querySelectorAll('.a-text[data-type="error"]').forEach((errorElement) => errorElement.remove());

		if (this.sumFloat === 0) {
			formData.append('paymentMethod', 'prepayment');
			paymentMethod = 'prepayment';
		}

		try {
			// Some payment method need additional JavaScript
			if (paymentMethod === 'credit-card-sca' || paymentMethod === 'sepa-debit') {
				let error = null;
				// Optional: Add billing details like name, postal code or city to the stripe request.

				if (paymentMethod === 'credit-card-sca') {
					const clientSecret = await this.getClientSecret('card');
					// https://stripe.com/docs/js/payment_intents/confirm_card_payment
					({ error } = await stripe.confirmCardPayment(clientSecret, {
						payment_method: {
							card: this.stripeElements.getElement('card'),
							billing_details: {
								name: formElement.name.value,
								email: formElement.email.value,
							},
						},
					}));
				} else if (paymentMethod === 'sepa-debit') {
					const clientSecret = await this.getClientSecret('sepa_debit');
					({ error } = await stripe.confirmSepaDebitPayment(clientSecret, {
						payment_method: {
							sepa_debit: this.stripeElements.getElement('iban'),
							billing_details: {
								name: formElement.name.value,
								email: formElement.email.value,
							},
						},
					}));
				}
				if (error) {
					throw error;
				}
			}

			// submit form
			await fetch(formElement.action, {
				body: formData,
				method: 'POST',
				headers: {
					'x-language': document.querySelector('html').lang,
				},
			})
				.then((response) => response.json())
				.then((data) => {
					if (data.status === 201) {
						window.location = data.redirect;
					} else if (data.key === 'error.merx.fieldsvalidation') {
						this.createFieldErrors(data.details);
					} else {
						throw data;
					}
				});
		} catch (error) {
			this.mFormCheckoutErrorElement.appendChild(this.createErrorElement(error.message));
		}
		this.loading = false;

		return false;
	}
}

document.querySelectorAll('.o-checkout').forEach((checkoutElement) => new OCheckout(checkoutElement));
